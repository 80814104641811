import Vue from 'vue';
import 'js-cloudimage-responsive';
// Require svg icons for webpack
require.context('./assets/icons', false, /.*\.svg$/);
import './assets/icomoon/icon-style.css';
// Import global styles.
// Configurations like variables or mixins will be included via webpack
import './assets/styles/global.scss';
// Import theme specific css
import './themes/minimal/minimal-index.scss';
import './themes/middle/middle-index.scss';
import isCockpitPreview from './is-cockpit-preview';
// Mixins
import tryToLocateImage from './mixins/tryToLocateImage';
// Filters
import wrapFirstNumber from './filters/filters';
// Services
import { eventHub, EVENT_NAMES } from './services/eventService';
import { trackChatWidget, initializePageTracking } from './services/trackingService';
import { lazyLoadMagazinePreview, preventScrollInPreview } from './services/previewService';
import listenForModalToggling from './services/domService';
// Components
import components from './components/index';

new Vue({
    el: '#app',
    mixins: [tryToLocateImage],
    filters: { wrapFirstNumber },
    components,
    mounted() {
        if (isCockpitPreview) {
            document.documentElement.style.pointerEvents = 'none';
            import(
                /* webpackChunkName: "iframe-service" */
                './services/iframeService'
            );
        }

        initializePageTracking(isCockpitPreview);
        listenForModalToggling();
        trackChatWidget();
        lazyLoadMagazinePreview();
        preventScrollInPreview();

        // use cdn for production-build (excluding preview) and local images otherwise
        if (process.env.PRODUCTION_BUILD && !isCockpitPreview) {
            /*
                The backend delivers the sources of local images with a leading `/`,
                which the ci-script won't handle properly with an aliased baseURL.
                Therefore we slice it off.
            */
            document.querySelectorAll('[ci-src]').forEach(img => {
                const ciSrc = img.getAttribute('ci-src');
                if (ciSrc[0] === '/') {
                    img.setAttribute('ci-src', ciSrc.slice(1));
                }
            });

            const ci = new window.CIResponsive({
                token: window.cdnToken,
                baseURL: window.cloudimageUrlWithTokenAndAlias,
                doNotReplaceURL: true
            });

            this.addAdvisorInitializer(() => ci.process());
        } else {
            this.setImgSrcs(document);
            this.addAdvisorInitializer(this.setImgSrcs);
        }
    },
    methods: {
        // manually init dynamically loaded advisor images
        addAdvisorInitializer(callback) {
            eventHub.$on(EVENT_NAMES.OPEN_ADVISOR, ({ target }) => {
                callback(target);
            });
        },
        setImgSrcs(target) {
            target.querySelectorAll('[ci-src]').forEach(img => {
                img.src = img.getAttribute('ci-src');
                img.removeAttribute('ci-src');
            });
            this.tryToLocateImageInDocument();
        }
    }
});